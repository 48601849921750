import { useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";

const ClickPictureComponent = ({ qrScannedData, onImageStamped }) => {
	// console.log({ showQrScanner });
	const [showModal, setShowModal] = useState(false);
	const [dimensions, setDimensions] = useState({ width: window.innerWidth, height: window.outerWidth });
	const [clickedAtUnix, setClickAtUnix] = useState();
	const [position, setPosition] = useState({ latitude: 0.0, longitude: 0.0 });
	const [capturedImage, setCapturedImage] = useState(null);

	const { width, height } = dimensions;

	const videoRef = useRef(null);
	const canvasRef = useRef(null);
	const sealRef = useRef(null);

	useEffect(() => {
		if (videoRef === null) {
			return;
		}

		let video = videoRef.current;

		if (!video) {
			return;
		}

		navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment", height: { ideal: 1500 }, width: { ideal: 1500 } } }).then(
			(stream) => {
				let { width, height } = stream.getVideoTracks()[0].getSettings();
				setDimensions({ width, height });

				video.srcObject = stream;
				video.play();
			},
			(error) => {
				console.log(error);
			}
		);
	}, [videoRef, showModal]);

	const handlePictureClick = () => {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition((position) => {
				const {
					coords: { latitude, longitude },
					timestamp,
				} = position;
				setPosition({ latitude, longitude, timestamp });
			});
		}

		const canvas = canvasRef.current;
		const ctx = canvas.getContext("2d");

		//Drawing Main Image
		const video = videoRef.current;
		ctx.drawImage(video, 0, 0, width, height);

		//Drawing Locad Seal
		const sealImage = sealRef.current;

		// const sealHeight = sealImage.height;
		const sealWidth = sealImage.width;
		const sealX = width - sealWidth;
		const sealY = 20;
		ctx.drawImage(sealImage, sealX, sealY, 150, 50);

		//Drawing Text Stamp
		const textHeight = 50;
		const x = 0;
		const y = height - textHeight;
		const y1 = y + 2;
		const y2 = y + 25;
		ctx.textBaseline = "top";
		ctx.fillStyle = "red";
		ctx.fillRect(x, y, width, textHeight);
		ctx.fillStyle = "white";
		ctx.font = "20px Arial";
		const timeStamp = new Date();
		setClickAtUnix(timeStamp.getTime());
		const dateString = timeStamp.toLocaleString();
		let header1 = "Time : " + dateString;
		if (position !== null) {
			const { latitude, longitude } = position;
			header1 = `${header1} Lat : ${latitude} Lng : ${longitude}`;
		}
		ctx.fillText(header1, x, y1);

		const { city, state, locality } = JSON.parse(qrScannedData);

		let header2 = `${locality} ,${city} | ${state}`;
		ctx.fillText(header2, x, y2);
		//Text Stamp Drawing Finished

		const type = "image/png";

		const base64Img = canvas.toDataURL(type, 0.1);
		setCapturedImage(base64Img);
		setShowModal(false);
		onImageStamped(base64Img);
	};

	return (
		<div>
			<Button
				variant="success"
				onClick={() => {
					setShowModal(true);
				}}
			>
				Open Click Picture
			</Button>
			<Modal
				show={showModal}
				onHide={() => {
					setShowModal(false);
				}}
			>
				<Modal.Header>Click Picture Component</Modal.Header>
				<Modal.Body>
					<video style={{ width: "100%", height: "100%" }} autoPlay ref={videoRef}></video>
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={handlePictureClick}>Click Picture</Button>
					<Button
						variant="danger"
						onClick={() => {
							setShowModal(false);
						}}
					>
						CLOSE
					</Button>
				</Modal.Footer>
			</Modal>
			<canvas ref={canvasRef} style={{ display: "none" }} id="imageCanvas" height={height} width={width}></canvas>
			<img style={{ display: "none" }} ref={sealRef} id="locad_seal" src="./locad_seal_hq.png" alt="Locad Seal" />
		</div>
	);
};

export default ClickPictureComponent;
