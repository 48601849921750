import QrScanner from "qr-scanner";
import { useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";

const QrScannerComponent = ({ onQrScanned }) => {
	// console.log({ showQrScanner });
	const [showModal, setShowModal] = useState(false);

	const videoRef = useRef(null);

	useEffect(() => {
		if (videoRef === null) {
			return;
		}

		let video = videoRef.current;

		if (!video) {
			return;
		}

		const qrScanner = new QrScanner(
			video,
			(result) => {
				qrScanner.stop();
				setShowModal(false);
				onQrScanned(result);
			},
			{}
		);

		if (showModal) {
			qrScanner.start();
		} else {
			qrScanner.stop();
		}
	}, [videoRef, showModal, onQrScanned]);

	return (
		<div>
			<Button
				onClick={() => {
					setShowModal(true);
				}}
			>
				Open Qr Scanner
			</Button>
			<Modal
				show={showModal}
				onHide={() => {
					setShowModal(false);
				}}
			>
				<Modal.Header>Scan Qr Code</Modal.Header>
				<Modal.Body>
					<video style={{ width: "100%", height: "100%" }} autoPlay ref={videoRef}></video>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="danger"
						onClick={() => {
							setShowModal(false);
						}}
					>
						CLOSE
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
};

export default QrScannerComponent;
