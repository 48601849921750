import { useState } from "react";
import { Container, Row, Col, Alert } from "react-bootstrap";
import QrScannerComponent from "./QrScannerComponent";
import ClickPictureComponent from "./ClickPictureComponent";
import { toast } from "react-toastify";

function App() {
	const [qrScannedData, setQrScannedData] = useState(null);
	const [stampedImage, setStampedImage] = useState(null);
	const [qrError, setQrError] = useState(null);

	const onQrScanned = (result) => {
		console.log(result);

		const { data } = result;
		setQrScannedData(data);

		try {
			const dataJSON = JSON.parse(data);
			const { city, state, locality } = dataJSON;
			setQrError(!city || !state || !locality);
			toast("Qr Scanned");
		} catch (error) {
			console.log(error);
			setQrError(true);
			toast("Check Qr Code");
		}
	};

	const onImageStamped = (base64Image) => {
		setStampedImage(base64Image);
	};

	return (
		<Container>
			<Row>
				<h1>Qr Scanner</h1>

				<Col xs={12}>
					<QrScannerComponent onQrScanned={onQrScanned} />
				</Col>
				{qrScannedData && (
					<Col xs={12}>
						<Alert variant="info">{JSON.stringify(qrScannedData)}</Alert>
					</Col>
				)}
				{qrError && (
					<Col xs={12}>
						<Alert variant="danger">Qr Code Not Supported</Alert>
					</Col>
				)}
				{qrScannedData && !qrError && (
					<Col xs={12}>
						<ClickPictureComponent qrScannedData={qrScannedData} onImageStamped={onImageStamped} />
					</Col>
				)}
				{stampedImage && (
					<Col>
						<img style={{ width: "100%", height: "100%" }} src={stampedImage} alt="Stamped Image" />
					</Col>
				)}
			</Row>
		</Container>
	);
}

export default App;
